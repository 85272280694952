<template>
  <vs-chip
    style=" height:30px; margin-top:5px; margin-left: 40%; margin-right: 40%"
    :color="getChipColor()"
  >
    {{ params.value }}
  </vs-chip>
</template>

<script>
import utilities from "@/app/shared/utilities";
import Vue from "vue";

export default Vue.extend({
  name: "AgChip",
  methods: {
    getChipColor() {
      return utilities.getChipColor(this.params.value);
    }
  }
});
</script>

<style scoped>

</style>
