<template>
  <div>
    <vs-card>
      <vs-row
        vs-type="flex"
        vs-justify="center"
      >
        <vs-col
          vs-type="flex"
          vs-justify="center"
          vs-align="center"
          vs-w="6"
        >
          <import-excel :on-success="importExcelFile"/>
        </vs-col>
      </vs-row>
    </vs-card>
    <vs-card v-can="permissions.edit">
      <vs-row
        vs-justify="center"
        vs-type="flex"
      >
        <h3 class="text-primary">
          حدد عدة حجوزات لتغيير حالتها
        </h3>
      </vs-row>
      <vs-row
        vs-justify="center"
        vs-type="flex"
      >
        <div class="demo-alignment">
          <vs-button
            @click="confirmChangeMultipleRecords('ACTIVE')"
            color="success"
            size="small"
            type="filled"
          >
            تنشيط
          </vs-button>
          <vs-button
            @click="confirmChangeMultipleRecords('CANCELED')"
            color="danger"
            size="small"
            type="filled"
          >
            تعطيل
          </vs-button>
          <vs-button
            @click="confirmChangeMultipleRecords('USED')"
            color="primary"
            size="small"
            type="filled"
          >
            تعيين كمستخدمة
          </vs-button>
          <vs-button
            @click="confirmChangeMultipleRecords('PAID')"
            color="#5F7A99"
            size="small"
            type="filled"
          >
            تم الدفع
          </vs-button>
          <vs-button
            @click="confirmChangeMultipleRecords('NOT_PAID')"
            color="#8158A4"
            size="small"
            type="filled"
          >
            غير مدفوع
          </vs-button>
          <vs-button
            @click="confirmChangeMultipleRecords('INVITATION')"
            color="#FE948E"
            size="small"
            type="filled"
          >
            دعوة
          </vs-button>
        </div>
      </vs-row>
    </vs-card>
    <ag-table
      :data-source-api="getAll"
      :grid-options="gridOptions"
      :permissions="permissions"
      :rtl="false"
      @emitAddNewRecord="createRecord()"
      @emitDeleteMultipleRecords="deleteEventUser($event, null , true)"
      @emitExportToExcel="exportToExcel"
      @emitShowCanceledRecords="hideChangeStatusButtons(true)"
      @emitShowCurrentRecords="hideChangeStatusButtons(false)"
    />

    <store-popup
      :event-user="eventUser"
      @updateGrid="updateGrid"
      ref="storePopupRef"
    />
    <update-popup
      :event-user="eventUser"
      @updateGrid="updateGrid"
      ref="updatePopupRef"
    />
  </div>
</template>

<script>
  import {RepositoryFactory} from '../event-repositories/eventRepositoryFactory';
  import AgTable from '../../shared/shared-components/ag-grid/AgTableServerSide.vue';
  import AgStatusButton from '../event-components/AgStatusButton.vue';
  import AgChip from '../event-components/AgChip.vue';
  import utilities from "@/app/shared/utilities";
  import AgTableBtnCell from "../../shared/shared-components/ag-grid/AgTableBtnCell";
  import StorePopup from "./StorePopup";
  import UpdatePopup from "./UpdatePopup";
  import ImportExcel from "../../../components/excel/ImportExcel.vue";

  const eventUsersRepo = RepositoryFactory.get("eventUsersRepository");
  const eventsRepo = RepositoryFactory.get("eventsRepository");

  export default {
    name: "EventUsers",
    components: {
      AgTable,
      StorePopup,
      UpdatePopup,
      ImportExcel
    },
    data() {
      return {
        gridApi: null,
        events: [],
        eventUser: {
          id: '',
          user_name: '',
          mobile: '',
          type: '',
          event_info: [],
          status: '',
          message: '',
          chair_number: ''
        },
        hideButtons: false,
        event: {
          id: '',
          date: '',
          showEvent: ''
        },
        gridOptions: null,
        permissions: {
          add: 'store_event_user',
          delete: 'destroy_event_user',
          edit: 'update_event_user'
        }
      };
    },
    methods: {
      deleteEventUser(ids, index, isMultiple = false) {
        if (isMultiple)
          ids = ids.join(',');
        eventUsersRepo.deleteEventUser(ids).then((response) => {
          if (response.code === 200) {
            this.gridApi.refreshServerSideStore({purge: true});
          }
        });
      },
      getAll(query) {
        return eventUsersRepo.fetchEventUsersByEventDefinitionId(this.$route.params.id, query).then((response => response));
      },
      createColDefs() {
        let self = this;
        return [
          {
            headerName: 'المعرف',
            field: 'id',
            suppressSizeToFit: true,
            checkboxSelection: true,
            filter: 'agNumberColumnFilter',
            width: 100,
          },
          {
            headerName: 'الاسم',
            field: 'user_name',
            filter: 'agTextColumnFilter',
          },
          {
            headerName: 'رقم الموبايل',
            field: 'mobile',
            filter: 'agTextColumnFilter',
          },
          {
            headerName: 'رقم الكرسي',
            field: 'chair_number',
            filter: 'agNumberColumnFilter',
          },
          {
            headerName: 'تاريخ الحفلة',
            field: 'event.date',
            filter: 'agDateColumnFilter',
            sortable: false
          },
          {
            headerName: 'يوم الحفلة',
            field: 'event.name',
            filter: 'agTextColumnFilter',
          },
          {
            headerName: 'وقت الحفلة',
            field: 'event.from_time',
            filter: 'agTextColumnFilter',
            sortable: false,
          },
          {
            headerName: 'تاريخ الحجز',
            field: 'created_at',
            valueFormatter: self.dateFormatter,
            filter: 'agDateColumnFilter',
            sortable: false
          },
          {
            headerName: 'نوع الحجز',
            cellRendererFramework: AgChip,
            field: 'type',
            filter: 'agSetColumnFilter',
            filterParams: {
              values: ['PAID', 'NOT_PAID', 'INVITATION']
            },
          },
          {
            headerName: 'حالة الحجز',
            cellRendererFramework: AgChip,
            field: 'status',
            filter: 'agSetColumnFilter',
            filterParams: {
              values: ['ACTIVE', 'CANCELED', 'USED']
            },
          },
          {
            headerName: 'نوع عملية الدفع',
            field: 'payment_type',
            filter: 'agSetColumnFilter',
            filterParams: {
              values: ['SYRIATEL', 'MTN', 'FATORA','CASH']
            },
          },
          {
            headerName: 'معرف عملية الدفع',
            field: 'payment_id',
            filter: 'agNumberColumnFilter',
          },
          {
            headerName: 'مرجع عملية الدفع',
            field: 'payment_reference',
            filter: 'agTextColumnFilter'
          },
          {
            headerName: 'تغيير حالة الحجز',
            cellRendererFramework: AgStatusButton,
            valueGetter:
              function getObject(params) {
                return params;
              },
            sortable: false,
            width: 250,
            cellRendererParams: {
              size: 'small',
              color: 'primary',
              permission: self.permissions.edit,
              hide: self.hideButtons,
              refresh() {
                self.gridApi.refreshServerSideStore({purge: true});
              }
            }
          },
          {
            headerName: 'الإجراءات',
            valueGetter:
              function getObject(params) {
                return params.data.id;
              },
            cellRendererFramework: AgTableBtnCell,
            cellRendererParams: {
              deleteRecord: function (valueGetter, rowIndex) {
                self.deleteEventUser(valueGetter, rowIndex);
              },
              editRecord: function (eventUser) {
                self.editRecord(eventUser);
              },
              actions: ['edit', 'delete'],
              permissions: self.permissions
            },
            sortable: false,
            width: 100,
          },
        ];
      },
      rowClicked(id) {
        this.$router.push(`/eventUsers/${id}`);
      },
      createRecord() {
        utilities.initObjectMembers(this.eventUser);
        this.$refs.storePopupRef.open();
      },
      editRecord(eventUser) {
        Object.assign(this.eventUser, eventUser);
        this.$refs.updatePopupRef.open();
      },
      confirmChangeMultipleRecords(status) {
        this.status = status;
        if (!this.gridOptions.api.getSelectedRows().length)
          return;
        let recordsIds = this.gridOptions.api.getSelectedRows().map(element => element.id);
        let title = ' هل أنت متأكد من تغيير حالة ' + recordsIds.length + '  من العناصر ؟ ';
        this.$vs.dialog({
          type: 'confirm',
          color: 'primary',
          title: title,
          text: 'في حال الموافقة سيتم تغيير حالة الحجوزات المحددة',
          acceptText: 'موافق',
          accept: this.changeStatus,
          parameters: [recordsIds]
        });
      },
      changeStatus(ids) {
        if (this.status === 'CANCELED' || this.status === 'ACTIVE' || this.status === 'USED')
          eventUsersRepo.changeStatus(ids[0], this.status).then(() => {
            this.gridApi.refreshServerSideStore({purge: true});
          });
        else eventUsersRepo.changeType(ids[0], this.status).then(() => {
          this.gridApi.refreshServerSideStore({purge: true});
        });

      },
      fetchAllEvents() {
        eventsRepo.fetchAllEventsByEventDefinitionId(this.$route.params.id, '?filter[is_active]=1').then((data) => {
          this.events = data;
          this.events.map(e => {
            e.showEvent = ` ${e.name}- ${e.date}/ ${e.from_time}-> ${e.to_time} /السعة المتبقية:${e.rest_capacity} /رقم أخر كرسي محجوز:` + this.checkLatestChair(e.latest_chair);
            return e;
          });
        });
      },
      onSubmit() {
        if (this.eventUser.id !== null) {
          this.updateEventUser(this.eventUser);
        } else {
          this.storeEventUser(this.eventUser);
        }
      },
      getChipColor(params) {
        return utilities.getChipColor(params);
      },
      hideChangeStatusButtons(hide) {
        if (hide)
          this.hideButtons = true;
        else
          this.hideButtons = false;
      },
      dateFormatter(params) {
        return utilities.dateFormatter(params.data.created_at);
      },
      exportToExcel() {
        eventUsersRepo.exportEventUsersToExcel(this.$route.params.id);
      },
      updateGrid() {
        this.gridApi.refreshServerSideStore({purge: true});
      },
      async importExcelFile(excelData) {
        await eventUsersRepo.updateEventUsersFromExcel(excelData.rawFile);
        await this.gridApi.refreshServerSideStore({purge: true});
      },
    },
    created() {
      this.gridOptions = {};
      this.gridOptions.columnDefs = this.createColDefs();
    },
    mounted() {
      this.gridApi = this.gridOptions.api;
    }
  };
</script>
