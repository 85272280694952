<template>
  <tm-popup
    :submit="onSubmit"
    ref="popup"
    title="تعديل حجز"
  >
    <div class="vx-row">
      <div class="vx-col w-full mb-2">
        <tm-input
          class="w-full"
          name="الاسم"
          placeholder="الاسم"
          type="text"
          v-model="eventUser.user_name"
          validation-rules="required"
        />
      </div>
    </div>
    <div class="vx-row">
      <div class="vx-col w-full mb-2">
        <tm-input
          class="w-full"
          name="رقم الموبايل"
          placeholder="رقم الموبايل"
          type="number"
          v-model="eventUser.mobile"
          validation-rules="required|length:10"
        />
      </div>
    </div>
    <div class="vx-row">
      <div class="vx-col w-full mb-2">
        <tm-select
          :options="statusOptions"
          :reduce="name => name.value"
          class="w-full"
          label="name"
          name="حالة الحجز"
          v-model="eventUser.status"
          validation-rules="required"
        />
      </div>
    </div>
    <div class="vx-row">
      <div class="vx-col w-full mb-2">
        <tm-select
          :options="typeOptions"
          :reduce="name => name.value"
          class="w-full"
          label="name"
          name="نوع الحجز"
          v-model="eventUser.type"
          validation-rules="required"
        />
      </div>
    </div>
    <div class="vx-row">
      <div class="vx-col w-full mb-2">
        <tm-select
          :options="events"
          :reduce="event => event"
          @input="isEventChange = true"
          class="w-full"
          dir="rtl"
          label="showEvent"
          name="تاريخ الحجز"
          v-model="eventUserEdit"
          validation-rules="required"
        />
      </div>
    </div>
    <div class="vx-row">
      <div class="vx-col w-full mb-2">
        <tm-input
          class="w-full"
          name="رقم الكرسي"
          placeholder="رقم الكرسي"
          type="number"
          v-model="eventUser.chair_number"
          validation-rules="required|numeric"
        />
      </div>
    </div>
  </tm-popup>
</template>

<script>
import {RepositoryFactory} from '../event-repositories/eventRepositoryFactory';
import TmPopup from "@/app/shared/shared-components/TmPopup";
import TmInput from "@/app/shared/shared-components/TmInput";
import TmSelect from "@/app/shared/shared-components/TmSelect";

const eventUsersRepo = RepositoryFactory.get("eventUsersRepository");
const eventsRepo = RepositoryFactory.get("eventsRepository");


export default {
  name: "UpdatePopup",
  data() {
    return {
      isEventChange: false,
      statusOptions: [
        {
          name: 'نشط',
          value: 'ACTIVE'
        },
        {
          name: 'معطل',
          value: 'CANCELED'
        },
        {
          name: 'مستعمل',
          value: 'USED'
        }
      ],
      typeOptions: [
        {
          name: 'مدفوع',
          value: 'PAID'
        },
        {
          name: 'غير مدفوع',
          value: 'NOT_PAID'
        },
        {
          name: 'دعوة',
          value: 'INVITATION'
        }
      ],
      events: [],
      disabled: false,
      disableAddButton: false,
      chairNumber: '',
      message: '',
      eventUserEdit: ''
    };
  },
  props: {
    eventUser: {
      type: Object,
      required: true
    }
  },
  methods: {
    onSubmit() {
      if (this.isEventChange) {
        this.eventUser.event_id = this.eventUserEdit.id;
        this.eventUser.event = this.eventUserEdit;
      }
      eventUsersRepo.updateEventUser(this.$objectToFD(this.eventUser), this.eventUser.id).then((response) => {
        this.$refs.popup.endLoading();
        if (response.code === 200) {
          this.$refs.popup.close();
          this.$emit("updateGrid");
          this.isEventChange = false;
        }
      });
    },
    fetchAllEvents() {
      eventsRepo.fetchAllEventsByEventDefinitionId(this.$route.params.id, '?filter[is_active]=1').then((data) => {
        this.events = data;
        this.events.map(e => {
          e.showEvent = ` ${e.name}- ${e.date}/ ${e.from_time}-> ${e.to_time} /السعة المتبقية:${e.rest_capacity} /رقم أخر كرسي محجوز:` + this.checkLatestChair(e.latest_chair);
          return e;
        });
      });
    },
    checkLatestChair(chairNumber) {
      if (chairNumber === null)
        return '';
      else return chairNumber;
    },
    open() {
      this.fetchAllEvents();
      this.eventUserEdit = ` ${this.eventUser.event.name}-${this.eventUser.event.date}/ ${this.eventUser.event.from_time}-> ${this.eventUser.event.to_time} /السعة المتبقية:${this.eventUser.event.rest_capacity} /رقم آخر كرسي محجوز : ${this.eventUser.event.latest_chair}`;
      this.$refs.popup.open();
    },
  },
  components: {
    TmPopup,
    TmInput,
    TmSelect
  },
};
</script>
