<template>
  <tm-popup
    :submit="onSubmit"
    ref="popup"
    title="إضافة حجز"
  >
    <div class="vx-row">
      <div class="vx-col w-full mb-2">
        <tm-input
          class="w-full"
          name="الاسم"
          placeholder="الاسم"
          type="text"
          v-model="eventUser.user_name"
          validation-rules="required"
        />
      </div>
    </div>
    <div class="vx-row">
      <div class="vx-col w-full mb-2">
        <tm-input
          class="w-full"
          name="رقم الموبايل"
          placeholder="رقم الموبايل"
          type="number"
          v-model="eventUser.mobile"
          validation-rules="required|length:10"
        />
      </div>
    </div>
    <div class="vx-row">
      <div class="vx-col w-full mb-2">
        <tm-select
          :options="statusOptions"
          :reduce="name => name.value"
          class="w-full"
          label="name"
          name="حالة الحجز"
          v-model="eventUser.status"
          validation-rules="required"
        />
      </div>
    </div>
    <div class="vx-row">
      <div class="vx-col w-full mb-2">
        <tm-select
          :options="typeOptions"
          :reduce="name => name.value"
          class="w-full"
          label="name"
          name="نوع الحجز"
          v-model="eventUser.type"
          validation-rules="required"
        />
      </div>
    </div>
    <vs-col
      vs-align="right"
      vs-justify="start"
      vs-type="flex"
      vs-w="12"
    >
      <vs-row class="mt-4">
        <vs-card>
          <div class="vx-row">
            <div class="vx-col w-full mb-2">
              <tm-select
                :options="events"
                :value="event"
                :reduce="event => event"
                @input="disableAddEventUserButton()"
                class="w-full"
                dir="rtl"
                label="showEvent"
                name="تاريخ الحجز"
                v-model="selectedEvent"
                validation-rules="required"
              />
            </div>
          </div>
          <div class="vx-row">
            <div class="vx-col w-full mb-2">
              <tm-input
                class="w-full"
                name="رقم الكرسي"
                placeholder="رقم الكرسي"
                type="number"
                v-model="chairNumber"
                validation-rules="required|numeric"
              />
            </div>
          </div>
          <vs-col
            class="mb-6"
            vs-lg="12"
          >
            <vs-button
              :disabled="disableAddButton"
              @click="addEventInfo()"
              color="primary"
              icon="icon-plus"
              icon-pack="feather"
              size="small"
              type="filled"
            >
              اضافة
            </vs-button>
          </vs-col>
          <div class="vx-row mt-4">
            <div class="vx-col w-full mb-2">
              <vs-table :data="eventInfo">
                <template slot="thead">
                  <vs-th>وقت الحفلة</vs-th>
                  <vs-th>رقم الكرسي</vs-th>
                  <vs-th>حذف</vs-th>
                </template>
                <template slot-scope="{data}">
                  <vs-tr
                    :key="index"
                    v-for="(info, index) in data"
                  >
                    <vs-td :data="data[index].name">
                      {{ data[index].name }}
                    </vs-td>
                    <vs-td :data="data[index].chair_number">
                      {{ data[index].chair_number }}
                    </vs-td>
                    <vs-td>
                      <vs-button
                        @click="deleteEventInfo(index)"
                        color="danger"
                        icon="icon-x"
                        icon-pack="feather"
                        size="small"
                        type="gradient"
                      />
                    </vs-td>
                  </vs-tr>
                </template>
              </vs-table>
            </div>
          </div>
        </vs-card>
      </vs-row>
    </vs-col>
    <!--    <div class="vx-row">-->
    <!--      <div class="vx-col w-full mb-2">-->
    <!--        <tm-select-->
    <!--          :options="whatsappTemplates"-->
    <!--          class="w-full"-->
    <!--          name="الرسالة"-->
    <!--          placeholder="اختر قالب رسالة الواتساب"-->
    <!--          :reduce="option => option"-->
    <!--          v-model="eventUser.message"-->
    <!--          validation-rules="required"-->
    <!--        />-->
    <!--      </div>-->
    <!--    </div>-->
  </tm-popup>
</template>

<script>
  import {RepositoryFactory} from '../event-repositories/eventRepositoryFactory';
  import TmPopup from "@/app/shared/shared-components/TmPopup";
  import TmInput from "@/app/shared/shared-components/TmInput";
  import TmSelect from "@/app/shared/shared-components/TmSelect";
  import toasted from "@/app/shared/utilities/toasted";
  // import {SharedRepositoryFactory} from "../../shared/shared-repositories/sharedRepositoryFactory";

  const eventUsersRepo = RepositoryFactory.get("eventUsersRepository");
  const eventsRepo = RepositoryFactory.get("eventsRepository");
  // const configRepo = SharedRepositoryFactory.get('configRepository');

  export default {
    name: "StorePopup",
    data() {
      return {
        statusOptions: [
          {
            name: 'نشط',
            value: 'ACTIVE'
          },
          {
            name: 'معطل',
            value: 'CANCELED'
          },
          {
            name: 'مستعمل',
            value: 'USED'
          }
        ],
        typeOptions: [
          {
            name: 'مدفوع',
            value: 'PAID'
          },
          {
            name: 'غير مدفوع',
            value: 'NOT_PAID'
          },
          {
            name: 'دعوة',
            value: 'INVITATION'
          }
        ],
        events: [],
        disabled: false,
        disableAddButton: false,
        //whatsappTemplates: [],
        eventInfo: [],
        selectedEvent: '',
        chairNumber: '',
        //message: '',
      };
    },
    props: {
      eventUser: {
        type: Object,
        required: true
      }
    },

    methods: {
      onSubmit() {
        //localStorage.setItem('whatsappMessage', JSON.stringify(this.eventUser.message));
        this.eventUser.event_info = this.eventInfo;
        eventUsersRepo.storeEventUser(this.$objectToFD(this.eventUser)).then((response) => {
          this.$refs.popup.endLoading();
          if (response.code === 200) {
            this.$refs.popup.close();
            this.$emit("updateGrid");
            this.disableAddButton = true;
            this.initEventUserInfo();
          }
        });
      },
      disableAddEventUserButton() {
        this.disableAddButton = false;
      },
      open() {
        this.fetchAllEvents();
        // let message = JSON.parse(localStorage.getItem('whatsappMessage'));
        // if (message !== null)
        //   this.eventUser.message = message;
        this.$refs.popup.open();
      },
      addEventInfo() {
        if (this.selectedEvent === null || this.chairNumber === '')
          toasted.failed('يجب إدخال تاريخ الحجز ورقم الكرسي');
        else if (this.selectedEvent.rest_capacity === 0)
          toasted.failed('لايمكن الحجز بهذا اليوم بسبب اكتمال العدد');
        else {
          let isChairNumberDuplicate = false;
          this.eventInfo.forEach(item => {
            if ((item.chair_number === this.chairNumber) && (item.event_id === this.selectedEvent.id))
              isChairNumberDuplicate = true;
          });
          if (isChairNumberDuplicate)
            toasted.failed('رقم الكرسي محجو ز مسبقاً');
          else {
            let eventUser = {
              chair_number: this.chairNumber,
              event_id: this.selectedEvent.id,
              name: this.selectedEvent.name
            };
            this.eventInfo.push(eventUser);
          }
        }
      },
      deleteEventInfo(index) {
        this.eventInfo.splice(index, 1);
      },
      // fetchWhatsappTemplates() {
      //   configRepo.getConfigByKey('whatsapp_templates').then((data) => {
      //     this.whatsappTemplates = data.field;
      //   });
      // },
      fetchAllEvents() {
        eventsRepo.fetchAllEventsByEventDefinitionId(this.$route.params.id, '?filter[is_active]=1').then((data) => {
          this.events = data;
          this.events.map(e => {
            e.showEvent = ` ${e.name}- ${e.date}/ ${e.from_time}-> ${e.to_time} /السعة المتبقية:${e.rest_capacity} /رقم أخر كرسي محجوز:` + this.checkLatestChair(e.latest_chair);
            return e;
          });
        });
      },
      initEventUserInfo() {
        this.eventInfo = [];
        this.selectedEvent = '';
        this.chairNumber = '';
      },
      checkLatestChair(chairNumber) {
        if (chairNumber === null)
          return '';
        else return chairNumber;
      }
    },
    // created() {
    //   this.fetchWhatsappTemplates();
    // },
    components: {
      TmPopup,
      TmInput,
      TmSelect
    },
  };
</script>
