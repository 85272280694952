<template>
  <div v-show="!params.hide">
    <vs-button
      v-can="params.permission"
      style="margin-right:5px"
      :size="params.size"
      :color="params.color"
      @click="clicked(firstButton)"
      type="filled"
    >
      {{ this.firstButton }}
    </vs-button>
    <vs-button
      style="margin-right:5px"
      v-can="params.permission"
      :size="params.size"
      :color="params.color"
      @click="clicked(secondButton)"
      type="filled"
    >
      {{ this.secondButton }}
    </vs-button>
  </div>
</template>

<script>
import Vue from "vue";
import {RepositoryFactory} from '../event-repositories/eventRepositoryFactory';

const eventUsersRepo = RepositoryFactory.get("eventUsersRepository");
export default Vue.extend({
  name: "AgStatusButton",
  data() {
    return {
      firstButton: '',
      secondButton: '',
      status: '',
      eventUserId: []
    };
  },
  created() {
    this.setButtonNames();
  },
  methods: {
    setButtonNames() {
      if (this.params.value.data.status === 'ACTIVE') {
        this.firstButton = 'تعطيل';
        this.secondButton = 'تعيين كمستخدمة';
      } else if (this.params.value.data.status === 'CANCELED') {
        this.firstButton = 'تنشيط';
        this.secondButton = 'تعيين كمستخدمة';
      } else {
        this.firstButton = 'تنشيط';
        this.secondButton = 'تعطيل';
      }

    },
    clicked(changeStatus) {
      if (changeStatus === 'تعطيل')
        this.status = 'CANCELED';
      else if (changeStatus === 'تنشيط')
        this.status = 'ACTIVE';
      else this.status = 'USED';
      this.eventUserId.push(this.params.value.data.id);
      eventUsersRepo.changeStatus(this.eventUserId, this.status).then(() => {
        this.params.refresh();
      });
    },
  }
});
</script>
